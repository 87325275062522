import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/pro-duotone-svg-icons";

const StyledNobel = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 17px;
  padding: 2px 20px 2px 2px;
  border: 1px solid var(--body-text-dark-theme);

  svg {
    --size: 30px;
    display: grid;
    align-items: center;
    width: var(--size);
    height: var(--size);
    margin: 0 10px;
    color: var(--highlight-dark-theme);
  }

  p {
    margin: 0;
  }

  @media (max-width: 600px) {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Nobel = ({ title }) => {
  return (
    <StyledNobel>
      <FontAwesomeIcon icon={faAward} />
      <p>{title}</p>
    </StyledNobel>
  );
};
