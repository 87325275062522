import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import ExternalLink from "../components/ExternalLink";
import { ImageTemplate } from "../components/ImageTemplate";
import { Nobel } from "../components/Nominee/Nobel";
import { SmallHeader } from "../components/SmallHeader";
import { Footer } from "../components/Footer";

import "./styles.css";
import "./typography.css";

const NomineeTemplateStyles = styled.section`
  max-width: var(--narrow-width);
  margin: 95px auto 0 auto;
  padding: 0 1rem;

  .nomineeHero {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto minmax(100px, 325px);

    .info {
      width: 100%;

      h2 {
        margin-top: 1rem;
      }
    }

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

const NobelList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }
`;

const NomineeTemplate = ({ data }) => {
  const { bio, image, location, name, nobels, title, website } =
    data.sanityNominee;
  const nonimeeImage =
    image.asset.localFile.childImageSharp.gatsbyImageData.images.fallback;
  const firstName = name.split(" ")[0];

  return (
    <NomineeTemplateStyles>
      <SmallHeader />
      <header className="nomineeHero">
        <div className="info">
          <h2>{name}</h2>
          <p>Title: {title}</p>
          <p>Location: {location}</p>
          <ExternalLink website={website}>
            {`${firstName}'s Website`}
          </ExternalLink>
          <p>{`Nobels ${firstName} is nominated for:`}</p>
          <NobelList>
            {nobels.map((nobel, i) => {
              const { mainImage, title } = nobel;
              return <Nobel key={i} mainImage={mainImage} title={title} />;
            })}
          </NobelList>
        </div>
        <ImageTemplate image={nonimeeImage} />
      </header>
      <main>
        <h3>Bio</h3>
        {bio.map((paragraph, index) => (
          <p key={index}>{paragraph.children[0].text}</p>
        ))}
      </main>
      <Footer />
    </NomineeTemplateStyles>
  );
};

export default NomineeTemplate;

export const query = graphql`
  query NomineeTemplateQuery($slug: String!) {
    sanityNominee(slug: { current: { eq: $slug } }) {
      website
      title
      nobels {
        title
      }
      name
      location
      image {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "max-width: 400px 300px 200px")
            }
          }
        }
      }
      bio {
        children {
          text
        }
      }
    }
  }
`;
