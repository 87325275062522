import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-duotone-svg-icons";

const ExternalLinkStyles = styled.a`
  display: flex;
  align-items: center;
  padding: 5px;
  color: var(--highlight-dark-theme);

  svg {
    margin-left: 5px;
  }
`;

const ExternalLink = ({ website, children }) => (
  <ExternalLinkStyles
    href={website}
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {children}
    <FontAwesomeIcon icon={faExternalLinkAlt} />
  </ExternalLinkStyles>
);

export default ExternalLink;
